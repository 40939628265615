<template>
  <div class="wrapper">
    <Header></Header>
    <div class="contentwrap">
      <el-row>
        <el-col :span="4">
          <Aside></Aside>
        </el-col>
        <el-col :span="20">
          <div class="content">
            <router-view></router-view>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Header from './Header'
import Aside from './Aside'
export default {
  name: 'layout',
  components: {
    Header,
    Aside,
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style scoped>
.wrapper {
  height: 100vh;
}
.contentwrap {
  min-height: 90%;
}
.content {
  margin: 15px 15px 0;
  min-height: 90vh;
  padding: 25px;
  background: #fff;
  box-sizing: border-box;
}
</style>
