<template>
  <div class="flexBtw header_content">
    <p class="logotext">四季教育学习管理中心</p>
    <el-dropdown @command="handleCommand"
                 trigger="click">
      <span class="el-dropdown-link">
        {{SName}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="bindstu">学员信息</el-dropdown-item>
        <el-dropdown-item command="changePsw">修改密码</el-dropdown-item>
        <el-dropdown-item command="Logut">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data () {
    return {
      SName: ''
    }
  },
  watch:{
    '$store.state.user.stu_students_info.StudentName':{
      handler(newName,oldName){
        this.SName=newName
      },
      deep:true,
    }
  },
  created () {
    this.SName = this.$store.state.user.stu_students_info?this.$store.state.user.stu_students_info.StudentName:'未绑定学生';
  },
  methods: {
    handleCommand (command) {
      if (command === 'Logut') {
        this.handleLogut()
      } else {
        this.$router.push('/'+command)
      }
    },
    handleLogut () {
      this.$store.dispatch("logout", {
        tips: '退出成功,请重新登录'
      })
    }


  },
  mounted () {
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.header_content {
  width: 100%;
  height: 76px;
  padding: 0 25px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  .logotext {
    font-size: 20px;
    color: #343434;
    font-weight: bold;
  }
}
</style>
<style lang="scss">
.header_content {
  .el-dropdown {
    font-size: 18px;
    cursor: pointer;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f1f2fa;
    color: #5d5d5d;
  }
}
</style>
