<template>
  <div class="aside">
    <el-menu class="el-menu-vertical-demo"
             @open="handleOpen"
             @close="handleClose"
             :collapse="isCollapse"
             background-color="#fff"
             text-color="#929292"
             active-text-color="#6f72ff"
             :unique-opened="open"
             :default-active="activeMenu"
             @select="handleSelect">
      <div v-for="(item,index) of headerData"
           :key="index">
        <el-menu-item :index="item.path">
          <span slot="title">{{item.meta.title}}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "Aside",
  data () {
    return {
      isCollapse: false,
      open: true,
      pathFlag: `${this.$route.path}`
    };
  },
  computed: {
    activeMenu () {
      const route = this.$route
      const { path, name } = route
      let navpath = '';
      if (name.indexOf('_')) {
        let pathone = name.split('_')[0];
        this.headerData.forEach(element => {
          if (pathone == element.name) {
            navpath = element.path
          }
        });
      } else {
        navpath = path
      }
      return navpath
    },
    headerData () {
      const routes = this.$router.options.routes;
      let menuarr = [];
      routes.forEach(element => {
        if (element.name == 'layout') {
          element.children.forEach(ele => {
            if (ele.meta.menu) {
              menuarr.push(ele)

            }
          });
        }
      });
      return menuarr
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.push(key)
    },
    handleOpen (key, keyPath) {
    },
    handleClose (key, keyPath) {
    },
  },
};
</script>

<style scoped lang="scss">
.aside {
  min-height: 90vh;
  background: #fff;
  .el-menu-item {
    font-size: 20px;
    padding-left: 5px !important;
    span {
      display: inline-block;
      width: 100%;
      height: 38px;
      line-height: 36px;
      padding-left: 20px;
    }
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff !important;
  }
  .el-menu-item.is-active {
    background-color: #fff !important;
    span {
      border-left: 3px solid #6f72ff;
      padding-left: 17px;
      background-color: #f1f2fa !important;
    }
  }
}
</style>

